import React from 'react'
import { MDXTag } from '@mdx-js/tag'


import DefaultLayout from "/Users/radu/code/ebe/src/components/BookLayout.js";
const layoutProps = {};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props);
    this.layout = DefaultLayout;
  }

  render() {
    const {
      components,
      ...props
    } = this.props;
    return <MDXTag name="wrapper" Layout={this.layout} layoutProps={Object.assign({}, layoutProps, props)} components={components}>

      <MDXTag name="p" components={components}>{`Este un material pentru creștere spirituală a credincioșilor și de formare de bază a slujitorilor.
De la memorare de versete la exerciții de subliniere în Scriptură; de la siguranțele de bază ale creștinului, la cunoașterea profundă a lui Hristos; de la o viață de rugăciune la o viață de slujire; de la părtășia personală cu Domnul la pregătirea și prezentarea mărturiei personale;`}</MDXTag>
      <MDXTag name="p" components={components}>{`Sunt doar câteva din lucrurile importante la care acest curs este de mare folos.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Este cursul care face ordine în mintea și viața noului credincios, care ajută la disciplinarea personală pe creștinul care vrea să fie un lucrător eficient…`}</MDXTag>
      <MDXTag name="p" components={components}>{`Dacă vrei să ajuți pe cineva să aibă o bază clară a încredințărilor sale biblice, dacă vrei ca membrii tineri, și nu numai, să fie adânc înrădăcinați în Hristos, oameni maturi pe calea credinței, atunci nu uita Colseni 2:7 …este inima cursului nostru.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Citat din curs: “Iată cine ne garantează siguranța biruinței! Dumnezeu este Cel care ne promite biruința, iar tu, întrucât ești copilul Lui, beneficiezi direct de ea, este a ta! Crede lucrul acesta și vei vedea cum lucrurile cu neputință de realizat la oameni, sunt posibile la Dumnezeu. Vei descoperi cu emoție că lanțurile obiceiurilor rele, care te-au legat, pot fi sfărâmate prin puterea Lui măreață. Memorează acest verset și scrie-l pe tăblița inimi tale: 1 Corinteni 10:13.”`}</MDXTag>
           </MDXTag>;
  }

}
export const _frontmatter = {};
    